<script>
import {mapActions, mapState} from "vuex";
import {convertTimestampToDateWithTime} from "@/utils/helpers/date.helpers";

export default {
  name: "TranscriptOrders",
  data(){
    return{
      activeIndex: 0,
    }
  },
  computed:{
    ...mapState('transcriptOrders', ['transcriptOrders']),
  },
  methods: {
    convertTimestampToDateWithTime,
    ...mapActions('transcriptOrders', ["GET_TRANSCRIPT_QUEUE", 'POST_TRANSCRIPT_ORDER', 'PUT_TRANSCRIPT_ORDER']),
    async changeStatus(order_id, status){
      let res = await this.PUT_TRANSCRIPT_ORDER({id: order_id, orderStatus: status})
      if (res.success) {
        await this.getData()
        this.$message({title: res.message})
      }
    },
    async getData(){
      await this.GET_TRANSCRIPT_QUEUE(1)
      await this.GET_TRANSCRIPT_QUEUE(2)
    }
  },
  async mounted() {
    await this.getData()
  }
}
</script>

<template>
  <div class="container">
    <h4 class="text-center my-3">Очередь транскрипта</h4>
    <TabView v-model:activeIndex="activeIndex" v-if="transcriptOrders.orderedList.data.length > 0 || transcriptOrders.printedList.data.length > 0">
      <TabPanel :header="transcriptOrders.orderedList.name">
        <DataTable v-if="transcriptOrders.orderedList.data.length > 0"
                   :value="transcriptOrders.orderedList.data" paginator :rows="10"
                   paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                   :rowsPerPageOptions="[10, 20, 50]" currentPageReportTemplate="Показано {first}-{last} из {totalRecords}">
          <Column header="№">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column header="ФИО студента">
            <template #body="{data}">
              {{data.student_last_name}} {{data.student_first_name}} {{data.student_middle_name}}
            </template>
          </Column>
          <Column header="Время создания заявки">
            <template #body="{data}">
              {{convertTimestampToDateWithTime(data.created_at)}}
            </template>
          </Column>
          <Column header="Курс">
            <template #body="{data}">
              {{data.student_study_course}}
            </template>
          </Column>
          <Column header="Баркод">
            <template #body="{data}">
              {{data.student_barcode}}
            </template>
          </Column>
          <Column header="Ссылка на транскрипт">
            <template #body="{data}">
              <a :href="`https://back.uib.kz/student/transcript/print-transcript?barcode=${data.student_barcode}`"
                 target="_blank">Транскрипт</a>
            </template>
          </Column>
          <Column header="Действия">
            <template #body="{data}">
              <Button :label="activeIndex == 0 ? 'Транскрипт распечатан' : 'Вернуть в активные заявки'"
                      icon="pi pi-check" class="mb-2" @click="changeStatus(data.id, activeIndex == 0 ? 2 : 1)"/>
            </template>
          </Column>
        </DataTable>
        <h5 v-else class="text-center my-2">Список пуст</h5>
      </TabPanel>
      <TabPanel :header="transcriptOrders.printedList.name">
        <DataTable v-if="transcriptOrders.printedList.data.length > 0"
                   :value="transcriptOrders.printedList.data">
          <Column header="№">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{data.student_last_name}} {{data.student_first_name}} {{data.student_middle_name}}
            </template>
          </Column>
          <Column header="Время создания заявки">
            <template #body="{data}">
              {{convertTimestampToDateWithTime(data.created_at)}}
            </template>
          </Column>
          <Column header="Решил(-а)">
            <template #body="{data}">
              {{data.office_name}} ({{data.office_name_eng}})
            </template>
          </Column>
          <Column header="Время распечатки">
            <template #body="{data}">
              {{convertTimestampToDateWithTime(data.printed_time)}}
            </template>
          </Column>
<!--          <Column header="Действия">-->
<!--            <template #body="{data}">-->
<!--              <Button :label="activeIndex == 0 ? 'Транскрипт распечатан' : 'Вернуть в активные заявки'"-->
<!--                      icon="pi pi-check" class="mb-2" @click="changeStatus(data.id, activeIndex == 0 ? 2 : 1)"/>-->
<!--            </template>-->
<!--          </Column>-->
        </DataTable>
        <h5 v-else class="text-center my-2">Список пуст</h5>
      </TabPanel>
    </TabView>
  </div>
</template>

<style scoped>

</style>

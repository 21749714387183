export function convertDateToDateInput(date) {
  return date.split(".").reverse().join("-")
}

export function convertDateToTimestamp(date) {
  return new Date(convertDateToDateInput(date)).getTime() / 1000
}

export function convertDateInputToTimestamp(date) {
  return new Date(date).getTime() / 1000
}

export function convertTimestampToDate(timestamp) {
  let date = new Date(timestamp * 1000)

  let month = date.getMonth() + 1
  month = (month < 10 ? '0' : '') + month

  let day = date.getDate()
  day = (day < 10 ? '0' : '') + day

  return `${date.getFullYear()}-${month}-${day}`
}

export function convertTimestampToDateWithTime(timestamp) {
  let date = new Date(timestamp * 1000);

  let year = date.getFullYear();

  let month = date.getMonth() + 1;
  month = (month < 10 ? '0' : '') + month;

  let day = date.getDate();
  day = (day < 10 ? '0' : '') + day;

  let hours = date.getHours();
  hours = (hours < 10 ? '0' : '') + hours;

  let minutes = date.getMinutes();
  minutes = (minutes < 10 ? '0' : '') + minutes;

  let seconds = date.getSeconds();
  seconds = (seconds < 10 ? '0' : '') + seconds;

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}